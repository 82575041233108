export default {
    
    component: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/layouts/DashBoardLayout'),
    children: [
        {
            path: 'portfolio',
            name: 'Portfolio',
            component: () => import(/* webpackChunkName: "portfolio" */ '@/modules/dashboard/layouts/PortfolioLayout'),
        },
        {
            path: ':wf/overview',
            name: 'Overview',
            component: () => import(/* webpackChunkName: "overview" */ '@/modules/dashboard/layouts/OverviewLayout'),
        },
        {
            path: ':wf/performance',
            name: 'Performance',
            component: () => import(/* webpackChunkName: "performance" */ '@/modules/dashboard/layouts/PerformanceLayout'),
        },
        {
            path: ':wf/extreme',
            name: 'Extreme',
            component: () => import(/* webpackChunkName: "extreme" */ '@/modules/dashboard/layouts/ExtremeLayout'),
        },
        {
            path: ':wf/fatigue',
            name: 'Fatigue',
            component: () => import(/* webpackChunkName: "fatigue" */ '@/modules/dashboard/layouts/FatigueLayout'),
        },        
        {
            path: ':wf/:wtg/overview',
            name: 'WtgOverview',
            component: () => import(/* webpackChunkName: "overview" */ '@/modules/dashboard/layouts/WtgOverviewLayout'),
        },
        {
            path: ':wf/:wtg/performance/',
            name: 'WtgPerformance',
            component: () => import(/* webpackChunkName: "performance" */ '@/modules/dashboard/layouts/WtgPerformanceLayout'),
        },
        {
            path: ':wf/:wtg/extreme/',
            name: 'WtgExtreme',
            component: () => import(/* webpackChunkName: "extreme" */ '@/modules/dashboard/layouts/WtgExtremeLayout'),
        },
        {
            path: ':wf/:wtg/fatigue/',
            name: 'WtgFatigue',
            component: () => import(/* webpackChunkName: "fatigue" */ '@/modules/dashboard/layouts/WtgFatigueLayout'),
        },
        {
            path: ':wf/:wtg/:component/extreme/',
            name: 'ComponentExtreme',
            component: () => import(/* webpackChunkName: "extreme" */ '@/modules/dashboard/layouts/ComponentExtremeLayout'),
        },
        {
            path: ':wf/:wtg/:component/fatigue/',
            name: 'ComponentFatigue',
            component: () => import(/* webpackChunkName: "fatigue" */ '@/modules/dashboard/layouts/ComponentFatigueLayout'),
        }
    ],
    
}