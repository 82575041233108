import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'


import vuetify from '@/plugins/vuetify'


import Highcharts from 'highcharts'
import VueHighcharts from 'vue-highcharts'
import highchartsMore from 'highcharts/highcharts-more'

highchartsMore(Highcharts)


import '@mdi/font/css/materialdesignicons.css'
import './global.css'

createApp(App)
.use(store)
.use(router)
.use(vuetify)
.use(VueHighcharts, {Highcharts})
.use(VueHighcharts, {Highcharts})
.mount('#app')