
export default{
    component: () => import(/* webpackChunkName: "management" */ '@/modules/management/layouts/ManagementLayout'),
    
    children: [
        {
            path: '/',
            redirect: '/management/reports',
        },
        
        {
            path: '/management/manuals',
            name: 'Manuals',
            component: () => import('../layouts/ManualsLayout'),
        },
        
        {
            path: '/management/reports',
            name: 'Reports',
            component: () => import('../layouts/ReportsLayout'),
        },

        {
            path: '/management/contracts',
            name: 'Contracts',
            component: () => import('../layouts/ContractsLayout'),
        },
    ]
}