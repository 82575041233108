import axios from 'axios';


const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  getStatus() {
    return apiClient.get('/status')
  },

    getWindfarms() {
        return apiClient.get('/windfarms')
    },

    getTurbines() {
        return apiClient.get('/turbines')
    },

    getComponents(wf){
      return apiClient.get(`/windfarm/${wf}/components`)
    },

    getAlerts(){
      return apiClient.get('/alerts')
    },

    getLocation(location){
      return apiClient.get(`/farm/${location}`)
    },

    getWindfarmsTime(){
      return apiClient.get('/windfarms/temp');
    },
    
    getWindfarmTime(time){
      return apiClient.get(`/windfarms/temp/${time}`)
    },
    
    getFarmTime(location, time){
      return apiClient.get(`/windfarms/temp/${location}/${time}`)
    },

    getTurbinesTime(location, time){
      return apiClient.get(`/turbines/temp/${location}/${time}`)
    },

    getWindfarmsPowerCurve(wf){
      return apiClient.get(`/windfarm/${wf}/powercurve`)
    },

    getExtremeComponent(idwfarm, wtg){
      return apiClient.get(`/windfarm/${idwfarm}/wtg/${wtg}/max-extreme`)
    },

    getWindfarmExtremeComponent(idwfarm){
      return apiClient.get(`/windfarm/${idwfarm}/max-extreme`)
    },

    getExtremeVariables(wtgModel, component){
      return apiClient.get(`/windfarm/wtg/model/${wtgModel}/${component}/extreme`)
    },

    getExtremeVariables2(idwfarm, wtg, component){
      return apiClient.get(`/windfarm/${idwfarm}/wtg/${wtg}/component/${component}/extreme`)
    },

    getWindfarmFatigue(idwfarm){
      return apiClient.get(`/windfarm/${idwfarm}/fatigue`)
    },

    getFatigueTurbine(idwfarm, wtg){
      return apiClient.get(`/windfarm/${idwfarm}/wtg/${wtg}/fatigue`)
    },

    getFatigueVariables(idwfarm, wtg, component){
      return apiClient.get(`/windfarm/${idwfarm}/wtg/${wtg}/component/${component}/fatigue`)
    },

    getEnergyWR(wtg){
      return apiClient.get(`/wrenergy/${wtg}`)
    },

    getWfPerformance(wf){
      return apiClient.get(`/windfarm/${wf}/performance`)
    }
};