import { createStore } from "vuex";
import fakeApi from "@/api/fakeApi";

export default createStore({
  state: {
    isLoggedIn: false,
    farms: {},
    farmsTime: {},
    timePeriod: "24h",
    components: {},
    ispinClicked: false,
    perfClicked: false,
    extClicked: false,
    fatigueClicked: false,
    activeChip: null, // Lens button clicked in the portfolio
    isPlotPressed: false, // Plot button clicked in the WTG's performance
  },

  mutations: {
    login(state) {
      state.isLoggedIn = true;
    },

    logout(state) {
      state.isLoggedIn = false;
    },
    setPlotPressed(state, value) {
      state.isPlotPressed = value;
    },
    SET_FARMS(state, farms) {
      for (let farmName in farms) {
        farms[farmName].icon = require("@/assets/dot-blue.gif");
      }
      state.farms = farms;
    },

    SET_ACTIVE_CHIP(state, value) {
      state.activeChip = value;
    },

    SET_FATIGUE_ICON(state, { farmName, icon }) {
      state.farms[farmName].icon = icon;
    },

    SET_FARMS_TIME(state, farmsTime) {
      state.farmsTime = farmsTime;
    },

    SET_TIME_PERIOD(state, value) {
      state.timePeriod = value;
    },

    SET_COMPONENTS(state, components) {
      state.components = components;
    },

    SET_ISPIN_CLICKED(state, value) {
      state.ispinClicked = value;
    },

    SET_PERF_CLICKED(state, value) {
      state.perfClicked = value;
    },

    SET_EXT_CLICKED(state, value) {
      state.extClicked = value;
    },

    SET_FATIGUE_CLICKED(state, value) {
      state.fatigueClicked = value;
    },
  },


  actions: {
    async fetchFarms({ commit }) {
      await fakeApi
        .getWindfarms()
        .then((response) => {
          commit("SET_FARMS", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async fetchFarmsTime({ commit }) {
      await fakeApi
        .getWindfarmsTime()
        .then((response) => {
          commit("SET_FARMS_TIME", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateActiveChip({ commit }, value) {
      commit("SET_ACTIVE_CHIP", value);
    },
    
    updateDefaultIcon({ commit, state }) {
      for (let farmName in state.farms) {
        if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName]
        ) {
          const icon = require("@/assets/dot-blue.gif");
          commit("SET_FATIGUE_ICON", { farmName, icon });
        }
      }
    },

    updateFatigueIcon({ commit, state }) {
      for (let farmName in state.farms) {
        if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "24h"
        ) {
          let icon;
          if (state.farms[farmName].fatlens[0] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "1m"
        ) {
          let icon;
          if (state.farms[farmName].fatlens[1] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "3m"
        ) {
          let icon;
          if (state.farms[farmName].fatlens[2] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "6m"
        ) {
          let icon;
          if (state.farms[farmName].fatlens[3] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "12m"
        ) {
          let icon;
          if (state.farms[farmName].fatlens[4] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        }
      }
    },

    updatePerformanceIcon({ commit, state }) {
      for (let farmName in state.farms) {
        if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "24h"
        ) {
          let icon;
          if (state.farms[farmName].perlens[0] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "1m"
        ) {
          let icon;
          if (state.farms[farmName].perlens[1] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "3m"
        ) {
          let icon;
          if (state.farms[farmName].perlens[2] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "6m"
        ) {
          let icon;
          if (state.farms[farmName].perlens[3] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "12m"
        ) {
          let icon;
          if (state.farms[farmName].perlens[4] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        }
      }
    },

    updateExtremeIcon({ commit, state }) {
      for (let farmName in state.farms) {
        if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "24h"
        ) {
          let icon;
          if (state.farms[farmName].extlens[0] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[0] == "yellow") {
            icon = require("@/assets/dot-yellow.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[0] == "orange") {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot_red_46.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "1m"
        ) {
          let icon;
          if (state.farms[farmName].extlens[1] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[1] == "yellow") {
            icon = require("@/assets/dot-yellow.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[1] == "orange") {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot_red_46.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "3m"
        ) {
          let icon;
          if (state.farms[farmName].extlens[2] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[2] == "yellow") {
            icon = require("@/assets/dot-yellow.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[2] == "orange") {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot_red_46.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "6m"
        ) {
          let icon;
          if (state.farms[farmName].extlens[3] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[3] == "yellow") {
            icon = require("@/assets/dot-yellow.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[3] == "orange") {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot_red_46.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        } else if (
          Object.prototype.hasOwnProperty.call(state.farms, farmName) &&
          state.farms[farmName] &&
          state.timePeriod == "12m"
        ) {
          let icon;
          if (state.farms[farmName].extlens[4] == "green") {
            icon = require("@/assets/dot-green.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[4] == "yellow") {
            icon = require("@/assets/dot-yellow.gif");
            console.log(farmName + "if");
          } else if (state.farms[farmName].extlens[4] == "orange") {
            icon = require("@/assets/dot-orange.gif");
            console.log(farmName + "if");
          } else {
            icon = require("@/assets/dot_red_46.gif");
            console.log(farmName + "else");
          }
          commit("SET_FATIGUE_ICON", { farmName, icon });
        }
      }
    },

    toggleIspinClicked({ commit, state }) {
      return new Promise((resolve) => {
        commit("SET_ISPIN_CLICKED", !state.ispinClicked);
        resolve();
      });
    },

    togglePerfClicked({ commit, state }) {
      return new Promise((resolve) => {
        commit("SET_PERF_CLICKED", !state.perfClicked);
        commit("SET_ISPIN_CLICKED", false);
        commit("SET_EXT_CLICKED", false);
        commit("SET_FATIGUE_CLICKED", false);
        resolve();
      });
    },

    toggleExtClicked({ commit, state }) {
      return new Promise((resolve) => {
        commit("SET_EXT_CLICKED", !state.extClicked);
        resolve();
      });
    },

    toggleFatigueClicked({ commit, state }) {
      return new Promise((resolve) => {
        commit("SET_FATIGUE_CLICKED", !state.fatigueClicked);
        resolve();
      });
    },

    fetchComponents({ commit }, wf) {
      var components = {};
      fakeApi
        .getComponents(wf)
        .then((response) => {
          var data = response.data;
          for (const section in data) {
            for (const component in data[section]) {
              components[component] = data[section][component];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      commit("SET_COMPONENTS", components);
    },
  },


  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
  },
});
